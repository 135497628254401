/** 
 * TASKS-ACTION-PLANS.WIDGET
 * Get actions plan by teams
 */

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import ListFields from "@/components/list-fields"
import { store } from "@/index"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { STATUS_LOADED } from "@/redux/_status.types"
import { actionPlanFetch, actionPlanFetchSelf, actionPlanGet } from "@/redux/action-plan.actions"
import { ActionPlan, ActionPlanState } from "@/redux/action-plan.types"
import { userFetch, userGet, userStatus } from "@/redux/user.actions"
import { User, UserState } from "@/redux/user.types"
import Dropdown from "@/components/dropdown"
import { Session } from "@/redux/_session.types"
import { v4 as uuid } from "uuid"
import Button from "@/components/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Space from "@/components/space"
import TasksActionPlanDisplayWidget from "./tasks-action-plan-display.widget"

interface StateProps{
  _session: Session
  actionPlan: ActionPlanState
  user: UserState
}

interface OwnProps{
  surveyId?: string
  onSelect: Function
  onStatusUpdate?: Function
}

type Props = StateProps & OwnProps & WithTranslation

function TasksActionPlansWidget(props: Props) {
  const { t } = props

  const [NEW_ACTION_PLAN_ID] = useState<string>(uuid())

  //Data is loading
  //const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedAdmin, setSelectedAdmin] = useState<User | undefined>(undefined)
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | undefined>(undefined)
  const [surveyActionPlans, setSurveyActionPlans] = useState<ActionPlan[]>([])

  //Load data
  //Fetch all observers for the account
  useEffect(() => {

    async function load(){

      updateStatus(true)

      const actionPlans: any = await store.dispatch(props._session.userRole === "ADMIN" ? actionPlanFetch() : actionPlanFetchSelf())
      const admins: any = await store.dispatch(userFetch("", null, null, true, "id,email,firstname,lastname,role"))
      
      if (actionPlans.error || admins.error){
        store.dispatch(userGet([]))
        store.dispatch(actionPlanGet([]))
      }else{

        setSelectedAdmin(admins[0])
        store.dispatch(userGet(admins))
        store.dispatch(actionPlanGet(actionPlans))

      }

      store.dispatch(userStatus(STATUS_LOADED))
      updateStatus(false)

    }

    load()

  }, [])

  useEffect(() => {
    if(props.surveyId){
      setSurveyActionPlans(props.actionPlan.list.filter((ap: ActionPlan) => ap.referenceSurveyId === props.surveyId))
    }
    else{
      setSurveyActionPlans(props.actionPlan.list)
    }
  }, [
    props.surveyId,
    props.actionPlan.list
  ])

  useEffect(() => {
    if(surveyActionPlans.length > 0){
      select(surveyActionPlans[surveyActionPlans.length - 1])
    }
  }, [
    surveyActionPlans
  ])

  function select(actionPlan: ActionPlan){
    setSelectedActionPlan(actionPlan)

    props.onSelect(actionPlan)
  }

  function updateStatus(loading: boolean){
    if(props.onStatusUpdate){
      props.onStatusUpdate(loading)
    }
  }

  return (
    <>

      { props.user.list.length === 0 &&
      <ListFields>
        {t("roles_empty")}
      </ListFields>
      }

      { surveyActionPlans.length > 0 &&
      <>
        <h3>{t("tasks_action_plan_existing")}</h3>

        <div className="flex flex-wrap">
          { surveyActionPlans.map((ap: ActionPlan) =>
          <TasksActionPlanDisplayWidget
            key={ap.id}
            actionPlan={ap}
            isSelected={(selectedActionPlan && ap.id === selectedActionPlan.id) ? true : false}
            isBlurred={(props.surveyId && props.surveyId !== ap.referenceSurveyId) ? true : false}
            onClick={() => select(ap)}
          />
          )}
        </div>

        <div className="height-20" />
      </>
      }

      <h3>{t("tasks_action_plan_new")}</h3>

      {( props.user.list.length > 0 && selectedAdmin ) ?
      <div className="flex">
        <div
          className="flex"
          style={{
            borderRadius: 8,
            padding: "20px 30px",
            border: "1px solid" + (( selectedActionPlan && selectedActionPlan.id === NEW_ACTION_PLAN_ID ) ? props._session.accountColors.active : "#e9e9e9")
          }}
        >

          <Dropdown active={selectedAdmin.id}
            displayField="username"
            list={props.user.list}
            title={t("tasks_responsible")}
            isDisabled={( selectedActionPlan && selectedActionPlan.id === NEW_ACTION_PLAN_ID )}
            onSelect={(user: User) => setSelectedAdmin(user)}
            status={props.user.status}
            value={selectedAdmin.username}
          />

          <div className="flex flex-dcol" style={{width: 220}}>
            <Space />
            
            {( selectedActionPlan && selectedActionPlan.id === NEW_ACTION_PLAN_ID ) ?
            <div className="flex" style={{margin: 14}}>
              <div className="flex flex-dcol">
                <Space />
                <FontAwesomeIcon icon={ faCheckCircle } color={ props._session.accountColors.active } />
                <Space />
              </div>
              
              <div style={{width: 5}} />

              <div className="grey-t flex flex-dcol">
                <Space />
                {t("tasks_action_plan_created")}
                <Space />
              </div>
            </div>
            :
            <Button
              className={selectedAdmin ? (surveyActionPlans.length === 0 ? "primary" : "secondary") : "light"}
              onClick={selectedAdmin ? () => select(new ActionPlan({
                id: NEW_ACTION_PLAN_ID,
                referenceSurveyId: props.surveyId,
                AccountId: props._session.accountId,
                UserIdResponsible: selectedAdmin.id,
                UserIdContact: props._session.userId,
                UserContact: new User({
                  id: props._session.userId,
                  firstname: props._session.userFirstname,
                  lastname: props._session.userLastname,
                  email: props._session.email
                })
              })) : undefined}  
            >
              {t("tasks_add")}
            </Button>
            }

          </div>

        </div>

        <Space />

      </div>
      :
      <div className="medgrey-t flex flex-auto"
        style={{ fontSize : 12 }}>
        {t("roles_empty")}
      </div>
      }

      <div className="height-20" />

      { surveyActionPlans.length === 0 &&
      <>
        <h3>{t("tasks_action_plan_existing")}</h3>

        <div className="medgrey-t flex flex-auto"
          style={{ fontSize : 12 }}>
          {t("tasks_teams_empty")}
        </div>

        <div className="height-20" />
      </>
      }

    </>
  )

}

const mapStateToProps = state => ({
  _session: state._session,
  actionPlan: state.actionPlan,
  user: state.user
})

export default connect(mapStateToProps)(withTranslation()(TasksActionPlansWidget))