/** 
 * TASKS-TOPICS.WIDGET
 * Get list of topics in order to set action
 */
import { withTranslation, WithTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ActionPlanState, ActionPlanTask } from "@/redux/action-plan.types"
import { Session } from "@/redux/_session.types"
import { Topic, TopicState } from "@/redux/topic.types"
import ListItem from "@/components/list-item"
import { orderBy } from "lodash"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import ListButton from "@/components/list-button"
import { useEffect, useState } from "react"
import DashboardQuestionsNoteWidget from "./dashboard-questions-note.widget"
import Space from "@/components/space"
import Link from "@/components/link"
import { store } from "@/index"
import { topicActivate } from "@/redux/topic.actions"
import TasksTopicModal from "@/modals/tasks-topic.modal"
import Dropdown from "@/components/dropdown"
import { Survey, SurveyState } from "@/redux/survey.types"
import { surveyActivate } from "@/redux/survey.actions"
import { actionPlanEdit } from "@/redux/action-plan.actions"
import TasksCreateChoiceWidget from "./tasks-create-choice.widget"
import Modal from "@/components/modal"
import SortMethodsWidget from "./sort-methods.widget"

interface StateProps extends WithTranslation{
  _session : Session
  actionPlan : ActionPlanState
  survey : SurveyState
  topic : TopicState
}

const MODAL_ADD: string = "MODAL_ADD"
const MODAL_TOPIC: string = "MODAL_TOPIC"
const TOPICS_DISPLAY: number = 5

function TasksTopicsWidget(props:StateProps) {
  const { t } = props

  //CurrentModal (dialog box)
  const [currentModal, setCurrentModal] = useState<string | null>(null)

  //Reduced display by default
  const [isReduced, setIsReduced] = useState(true)

  const [topics, setTopics] = useState<Topic[]>([])

  const [sort, setSort] = useState<"alphabetical" | "numerical">("numerical")

  const [order, setOrder] = useState<"asc" | "desc">("asc")

  useEffect(() => {
    const topicsWithTask: (string | null)[] = props.actionPlan.active.tasks.map((x: ActionPlanTask) => x.topicAid)
    setTopics(sort === "alphabetical" ? 
      orderBy(
        props.topic.list.filter((x: Topic) => x.withScore && !topicsWithTask.includes(x.aid)),
        "label",
        [order]
      )
    :
      orderBy(
        props.topic.list.filter((x: Topic) => x.withScore && !topicsWithTask.includes(x.aid)),
        "note",
        [order]
      )
    )
  }, [
    props.actionPlan.active.tasks,
    order,
    sort
  ])

  //Get list of topic
  //> Search only topics with score
  //> Do not include topic already selected for tasks
  function getTopics(){
    return isReduced ? topics.slice(0, TOPICS_DISPLAY) : topics
  }

  //Select survey and reload topics
  function selectSurvey(survey:Survey){
    const surveyId : string | null = survey.id === "" ? null : survey.id
    store.dispatch(surveyActivate(survey))
    store.dispatch(actionPlanEdit("referenceSurveyId", surveyId, true))
  }

  return (
    <div
      style={{
        width: 360,
        marginRight: 40,
        height: "calc(100vh - 290px)",
        overflowY: "auto",
        paddingRight: 28
      }}
    >

      { currentModal === MODAL_ADD &&
      <Modal
        title={t("tasks_list_add")}
        onClose={() => setCurrentModal(null)}
      >
        <TasksCreateChoiceWidget
          selectedTopic={props.topic.active}
          onClose={() => setCurrentModal(null)}
          isInModal
        />
      </Modal>
      }

      { currentModal === MODAL_TOPIC &&
      <TasksTopicModal onClose={() => setCurrentModal(null)}
      />
      }
      
      <div className="flex">
        <div style={{marginRight: 5}}>
          <Dropdown value={props.survey.active.name}
            displayField="name"
            isNullAllowed
            active={props.survey.active.id}
            list={props.survey.list}
            title={t("tasks_survey")}
            onSelect={(survey: Survey) => selectSurvey(survey)}
          />
        </div>

        <div className="flex flex-dcol">
          <Space />
          <SortMethodsWidget
            sortMethod={sort}
            order={order}
            onSortClic={(sortMethod: "alphabetical" | "numerical") => setSort(sortMethod)}
            onOrderClic={(orderItem: "asc" | "desc") => setOrder(orderItem)}
            hideItems={["ratio"]}
          />
        </div>

      </div>

      <div className="height-20"/>

      { getTopics().map((topic: Topic, i: number) => 
      <ListItem key={topic.aid}>
        <div className="flex flex1"
          style={{ margin : "0px -32px" }}>

          <DashboardQuestionsNoteWidget currentTopic={topic}/>

          <span className="_hover"
            onClick={() => {
              store.dispatch(topicActivate(topic))
              setCurrentModal(MODAL_TOPIC)
            }}>
            {topic.label}
          </span>

          <Space/>

          <div className="width-20"/>

          <div className="flex flex-auto">
            <ListButton icon={faCirclePlus}
              isPrimary
              onClick={() => {
                store.dispatch(topicActivate(topic))
                setCurrentModal(MODAL_ADD)
              }}
              isFat={i === 0 && props.actionPlan.active.tasks.length === 0}
              text={t("tasks_list_add")}
            />
          </div>

        </div>
      </ListItem>
      )}

      <div className="height-20"/>

      { topics.length > TOPICS_DISPLAY &&
      <div className="flex">
        <Space/>
        <Link isWithoutMargin
          onClick={() => setIsReduced(!isReduced)}>
          {isReduced ? t("tasks_topic_show_all") : t("tasks_topic_hide_all")}
        </Link>
      </div>
      }

    </div>
  )

}

const mapStateToProps = state => ({
  _session : state._session,
  actionPlan : state.actionPlan,
  survey : state.survey,
  topic : state.topic
})

export default connect(mapStateToProps)(withTranslation()(TasksTopicsWidget))