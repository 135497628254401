/** 
 * TASKS-GENERATE
 * Modal giving action plan's tasks ideas, using GPT
 */

import Modal from "@/components/modal";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Topic, TopicState } from "@/redux/topic.types";
import ConsentOpenaiWidget from "@/widgets/consent-openai.widget";
import { useState } from "react";
import { toast } from "react-toastify";
import { Session } from "@/redux/_session.types";
import { ActionPlan, ActionPlanTask } from "@/redux/action-plan.types";
import { v4 as uuid } from "uuid"
import { store } from "..";
import { actionPlanEdit, actionPlanUpdate } from "@/redux/action-plan.actions";
import TasksGeneratorWidget, { Idea } from "@/widgets/tasks-generator.widget";
import TasksActionPlansWidget from "@/widgets/tasks-action-plans.widget";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { sortBy } from "lodash";
import TopicTaskWidget from "@/widgets/topic-task.widget";
import Space from "@/components/space";
import FilterDescriptionDisplayerWidget from "@/widgets/filter-description-displayer.widget";
import { FilterState } from "@/redux/filter.types";

interface StateProps{
  _session: Session
  filter: FilterState
  topic: TopicState
}

interface OwnProps{
  selectedTopic?: Topic
  onClose?: Function
  selectedActionPlan?: ActionPlan
  surveyId?: string
}

type Props = StateProps & OwnProps & WithTranslation

function TasksGenerateModal(props: Props){
  const { t } = props

  /**
   * STEP 1: Select topic (if not already selected)
   * STEP 2: Select tasks to add to AP
   * STEP 3: Select existing AP (or create a new one)
   */
  const [step, setStep] = useState<number>(props.selectedTopic ? 2 : 1)
  const [selectedTopic, setSelectedTopic] = useState<Topic | undefined>(props.selectedTopic)
  const [selectedActionPlan, setSelectedActionPlan] = useState<ActionPlan | undefined>(props.selectedActionPlan)
  const [selectedIdeas, setSelectedIdeas] = useState<Idea[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  async function next(){

    if(selectedIdeas.length > 0){
      if(selectedActionPlan){
        const currentDate: Date = new Date()
        const newTasks: ActionPlanTask[] = [
          ...selectedActionPlan.tasks,
          ...selectedIdeas.map((idea: Idea) => new ActionPlanTask({
            createdAt: currentDate,
            createdBy: "openAi",
            id: uuid(),
            title: idea.title,
            text: idea.text,
            topicAid: selectedTopic?.aid
          }))
        ]
        const response: any = store.dispatch(actionPlanUpdate(new ActionPlan({ ...selectedActionPlan, tasks: newTasks })))
        if(!response.error){
          store.dispatch(actionPlanEdit("tasks", newTasks, false))
          toast(t("tasks_success"), { type: "success" })
          close()
        }
      }
      else{
        setStep(3)
      }
    }
    else{
      close()
    }
  
  }

  function close(){
    if(props.onClose){
      props.onClose()
    }
  }

  function generate(){
    setSelectedIdeas([])
    setStep(2)
  }

  function getContent(): JSX.Element{

    if(step === 1){
      return (
        <div
          style={{
            overflowY: "auto",
            width: 540,
            border: "solid 1px #e9e9e9",
            borderRadius: 8,
            padding: "20px 30px"
          }}
        >
          { sortBy(props.topic.list, "note").map((topic: Topic) =>
          <TopicTaskWidget
            key={topic.id}
            topic={topic}
            isActive={selectedTopic && topic.aid === selectedTopic.aid}
            onClick={() => setSelectedTopic(topic)}
          />
          )}
        </div>
      )
    }

    else if (step === 2){
      return (
        <TasksGeneratorWidget
          onSelect={(ideas: Idea[]) => setSelectedIdeas(ideas)}
          selectedTopic={selectedTopic!}
          onStatusUpdate={(loading: boolean) => setIsLoading(loading)}
        />
      )
    }

    else if (step === 3){
      return (
        <TasksActionPlansWidget
          surveyId={props.surveyId}
          onSelect={(ap: ActionPlan) => setSelectedActionPlan(ap)}
          onStatusUpdate={(loading: boolean) => setIsLoading(loading)}
        />
      )
    }

    else{
      return <div>{t("utils_error")}</div>
    }

  }

  function getActionOnNext(): Function | undefined{

    if(step === 1){
      if(selectedTopic){
        return generate
      }
      else{
        return undefined
      }
    }

    else if(step === 2){
      if(selectedIdeas.length > 0){
        return next
      }
      else{
        return undefined
      }
    }

    else if(step === 3){
      if(selectedActionPlan){
        return next
      }
      else{
        return undefined
      }
    }

    else{
      return undefined
    }

  }

  function getOwlText(): string{

    if(step === 1){
      if(selectedTopic){
        return t("tasks_topic_selected", {topic: selectedTopic.label})
      }
      else{
        return t("tasks_topic_select")
      }
    }

    else if(step === 2){
      if(isLoading){
        return t("tasks_generating")
      }
      else{
        return t("tasks_generated", {topic: (selectedTopic!.label).toLowerCase()})
      }
    }

    else if(step === 3){
      if(isLoading){
        return t("tasks_action_plan_loading")
      }
      else{
        return t("tasks_action_plan_select")
      }
    }

    else{
      return t("utils_error")
    }

  }

  return(
    <Modal
      isLarge
      onNext={getActionOnNext()}
      isCloseButtonVisible
      onClose={close}
    >

      <ConsentOpenaiWidget>

        <div className="flex rel">

          <div style={{width: 600}}>

            <div
              className="rel"
              style={{
                height: 100,
                fontSize: 14,
                background: "#C8C8C8",
                clipPath: "polygon(0% 0%, 90% 0%, 90% 50%, 100% 70%, 90% 90%, 90% 100%, 0 100%)",
              }}
            >
              <div
                className="abs flex flex-dcol"
                style={{
                  position: "absolute",
                  top: 1,
                  left: 1,
                  width: 598,
                  height: 98,
                  background: "white",
                  clipPath: "polygon(0% 0%, 90% 0%, 90% 50%, 100% 70%, 90% 90%, 90% 100%, 0 100%)",
                }}
              >
                <Space />

                <div
                  className="grey-t medgrey-b"
                  style={{
                    padding: "20px 90px 20px 30px",
                    textAlign: "center"
                  }}
                >
                  <b>{getOwlText()}</b>
                </div>

                <Space />
              </div>
            </div>

            {( !isLoading && step !== 3 ) ?
            <div className="grey-t" style={{ margin: "10px 0", fontSize: 12, fontWeight: 500 }}>

              { selectedTopic &&
              <div>{t("question") + " : " + selectedTopic.Questions[0].label}</div>
              }

              {( !props.filter.dashboard.isEmpty && window.location.href.includes("/dashboard/") ) &&
              <>
                <div>{t("filters") + " :"}</div>

                <FilterDescriptionDisplayerWidget
                  dashboardFilters={props.filter.dashboard}
                  alwaysMini
                />
              </>
              }

              {/*<div>{t("project_status") + " " + props._session.userRole}</div>*/}

            </div>
            :
            <div className="height-20"/>
            }

          </div>

          <Space />

          <img
            className="abs"
            src={require("@/assets/assistant/owl_female.png")}
            alt={"owl assistant"}
            width={130}
            style={{
              objectFit: "contain",
              transform: "scaleX(-1)",
              right: 10
            }}
          />

        </div>

        { isLoading &&
        <div className="rel height-100" >
          <div className="abs" style={{ left: "40%" }}>
            <FontAwesomeIcon icon={faCircleNotch} className="grey-t" spin size={"2x"} />
          </div>
        </div>
        }

        {getContent()}

      </ConsentOpenaiWidget>

    </Modal>
  )
}

const mapStateToProps = state => ({
  _session: state._session,
  filter: state.filter,
  topic: state.topic
})

export default connect(mapStateToProps)(withTranslation()(TasksGenerateModal))
