/**
 * TASKS-ACTION-PLAN-DISPLAY.WIDGET
 */

import ListCell from "@/components/list-cell"
import ListIcon from "@/components/list-icon"
import { ActionPlan } from "@/redux/action-plan.types"
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { withTranslation, WithTranslation } from "react-i18next"
import AdmUserPopulationsWidget from "./adm-user-populations.widget"
import Chip from "@/components/chip"
import ProgressBar from "@/components/progress-bar"
import { connect } from "react-redux"
import { Session } from "@/redux/_session.types"


interface OwnProps {
  actionPlan: ActionPlan
  isSelected?: boolean
  onClick?: Function
  isBlurred?: boolean
}

interface StateProps {
  _session: Session
}

type Props = OwnProps & StateProps & WithTranslation

function TasksActionPlanDisplayWidget(props: Props){
  const { t } = props

  const [actionPlan, setActionPlan] = useState<ActionPlan>(props.actionPlan)
  const [isSelected, setIsSelected] = useState<boolean>(props.isSelected ? true : false)

  useEffect(() => {
    setActionPlan(props.actionPlan)
  }, [
    props.actionPlan
  ])

  useEffect(() => {
    setIsSelected(props.isSelected ? true : false)
  }, [
    props.isSelected
  ])

  function click(){
    if(props.onClick){
      props.onClick()
    }
  }

  return (
    <div
      className={"flex flex-dcol" + ( props.onClick ? " _hover" : "" )}
      style={{ width: 200, marginRight: 5, marginBottom: 10, borderRadius: 8, border: "1px solid" + (isSelected ? props._session.accountColors.active : "#e9e9e9"), padding: "20px 30px", opacity: props.isBlurred ? 0.7 : 1 }}
      onClick={click}
    >

      { actionPlan.UserResponsible &&
      <div style={{ borderRadius: 8, border: "1px solid #e9e9e9", padding: "5px 10px" }}>

        <div className="flex">

          <ListIcon fallbackIcon={faUserCircle}
            image={actionPlan.UserResponsible.imageUrl}
          />

          <ListCell width={170} text={actionPlan.UserResponsible.username}/>

        </div>

        <div>{t("tasks_teams_populations") + " : "}</div>

        <AdmUserPopulationsWidget currentUser={actionPlan.UserResponsible}/>

      </div>
      }

      <div className="height-20" />

      <div style={{ height: 100, overflow: "hidden" }} >

        <div style={{ marginBottom: 6 }}>
          🎯 <b>{t("tasks_priority")}</b> : {actionPlan.priority ? actionPlan.priority : <i>{t("utils_undefined")}</i>}
        </div>

        <div style={{ marginBottom: 6, textOverflow: "ellipsis" }}>
          🏅 <b>{t("tasks_expected_outcome")}</b> : {actionPlan.expectedOutcome ? actionPlan.expectedOutcome : <i>{t("utils_undefined")}</i>}
        </div>
      
      </div>

      <div className="height-20" />

      <div className={"flex" + (actionPlan.status === "active" ? " flex" : " flex-dcol")}>

        <Chip>
          {actionPlan.statusLabel}
        </Chip>

        { actionPlan.status === "active" ?
        <div style={{ width: 100, marginLeft: 12 }}>
          <div style={{ margin: "-6px 0px"}}>
            <ProgressBar
              items={[
                {value: actionPlan.tasksDoneCount}
              ]}
              max={actionPlan.tasks.length}
            />
          </div>
        </div>
        :
        <div className="grey-t" style={{paddingTop: 10}}>
          {t("tasks_count", { count: actionPlan.tasks.length })}
        </div>
        }

      </div>
    
    </div>
  )
}

const mapStateToProps = state => ({
  _session: state._session
})

export default connect(mapStateToProps)(withTranslation()(TasksActionPlanDisplayWidget))